.variant_box {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 15.8125rem;
    height: 3.125rem;
    background-color: #D9D9D9;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
  .variant_box {
    width: 12.8125rem;
    height: 3.125rem;
  }
}

@media (max-width: 650px) {
  .variant_box {
    width: 9.8125rem;
    height: 3.125rem;
  }
}

@media (max-width: 570px) {
  .variant_box {
    width: 100%;
    border-radius: 0.375rem;
  }
}


.variant_box:hover{
  cursor: pointer;
}
.variant_box.active{
    background: #FCBC29;
}

.variant_box.white_box{
  animation: white_box 0.1s ease forwards ;
}

@keyframes white_box {
  0% {
    background-color: #36907E;
  }

  100% {
    background-color: #D9D9D9;
  }
}

/* @media (hover: none) {
  .variant_box:active { color: #ffffff; }
} */

.variant_box.wrong{
    animation: wrong 0.5s ease forwards;
}

@keyframes wrong {
  /* 0%, */

  0%{
    background-color: #FCBC29;
  }

  50% {
    background: linear-gradient(#c68c05, #865e02);
  }

  /* 80%, */

  100% {
    background: #c40424;
  }
    /* 0%,
    22%,
    42% {
      background: #FCBC29;
    }
    20%,
    40%,
    60% {
      background: linear-gradient(#c68c05, #865e02);
    }
    62%,
    100% {
      background: #C24328;
    } */
}

.variant_box.correct{
    animation: correct 0.5s ease forwards;
}

@keyframes correct {
  /* 0%, */
  0% {
    background-color: #FCBC29;
  }

  50% {
    background: linear-gradient(#c68c05, #865e02);
  }

  /* 80%, */

  100% {
    /* background: #36907E; */
    /* background: #1c4d44; */
    background: #246156;
  }
    /* 0%,
    22%,
    42% {
      background: #FCBC29;
    }
    20%,
    40%,
    60% {
      background: linear-gradient(#c68c05, #865e02);
    }
    62%,
    100% {
      background: #36907E;
    } */
}