.game_header_container {
    background-color: #3565a3;
    display: flex;
    align-items: center;
    width: 100%;
    
}

.empty_div{
    width: 33%;
}

.game_name__container {
    width: 33%;
    display: flex;
    justify-content: center;
}

.menubox__container {
    display: flex;
    justify-content: flex-end;
    width: 33%;
    margin-top: -0.5rem;
}

.game_name {
    font-size: 2.5rem;
    font-family: Kalam;
    font-weight: bolder;
    color: #D9D9D9;
    margin: 0px;
    margin-top: 0.5rem;
}


@media (max-width: 800px) {
    .game_name {
        font-size: 2.2rem;
        margin-top: 1rem;
    }
}

@media (max-width: 650px) {
    .game_name {
        font-size: 1.9rem;
        margin-top: 1rem;
    }
    .game_header_container{
        margin-bottom: -1px;
    }
}

@media (max-width: 570px) {
    .game_name {
        margin-top: 1.5rem;
        font-size: 2rem;
        line-height: 3.188125rem;
    }
    .menubox__container {
        margin-top: 1.2rem;
    }
}