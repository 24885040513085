.modal_container {
    display: flex;
    justify-content: center;
}

.backdrop {
    position: fixed;
    top: 9%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    z-index: 10;
    width: 100%;
    left: 0;
}

.modal {
    position: fixed;
    top: 10%;
    width: 50%;
    background: #BEBDBD;
    box-shadow: 0px 10px 20px rgba(0, 27, 72, 0.6);
    border-radius: 26px;    
    z-index: 100;
    padding: 15px 5px;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    /* left: 5%; */
}

.modal_text {
    font-family: Nunito;
    font-weight: bolder;
    color: #3565A3;
    font-size: 1.3rem;
    /* margin-top: 1rem; */
    margin-bottom: 0.5rem;
    /* margin: 0.9rem; */
}

.statistics_texts__container {
    margin-top: 1rem; 
    margin-bottom: 0.3rem;
    text-align: center;
}

.icons_and_names__container {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
}

.question_icon__and__q_name_container {
    display: flex;
    justify-content: end;
    width: 50%;
}

.siword_icon__and__si_name_container {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}

.icon_name {
    font-family: Nunito;
    font-weight: bolder;
    color: #3565A3;
    font-size: 1.5rem;
    margin: 0;
}

.siword_icon {
    margin-left: 1.5rem;
    margin-right: 0.7rem;
}

.question_icon {
    margin-right: 1rem;
}

.levels_container {
    width: 90%;
}

.game_timer__container {
    /* display: flex; */
    /* background-color: red; */
    width: 90%;
    text-align: center;
    margin-top: 1rem;
    font-size: 12px;
}

.modal_buttons_container {
    width: 100%;
    display: flex;
    margin-top: 0.2rem;
    /* background-color: aqua; */
}

.modal_buttons_container__noAuth {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
}

.go_to_settings_text__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background-color: #d76502; */
}

.share_button__container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.why_register_button__container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.go_to_statistics__button {
    font-family: Nunito;
    font-weight: bolder;
    /* color: #3565A3; */
    font-size: 1rem;
    /* margin: 0; */
    padding: 0.5rem;
    align-self: center;
}

.red_colour {
    color: red;
}

.orange_colour {
    color: #d76502;
}





@media (max-width: 580px) {
    .modal {
        width: 95%;

    }
 
    .backdrop {
        top: 13%
    }

    .modal_text {
        font-size: 1rem;
        margin-bottom: 0rem;
    }


    .statistics_texts__container {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .icon_name {
        font-size: 1.2rem;
    }

    .modal_buttons_container {
        margin: 0rem;
    }

   .siword_icon {
    margin-left: 1rem;
   }

   .question_icon {
    margin-right: 0.5rem;
   }
   
}

@media (max-height: 920px) {
    .modal{
     top: 12%;
    }
    .backdrop {
        top: 8%;
    }
}

@media (max-height: 800px) {
    .modal{
    top: 12%;
    }

    .backdrop {
        top: 9%;
    }
}

@media (max-height: 730px) {
    .modal{
    top: 10.5%;
    }

    .backdrop {
        top: 9.5%;
    }
}

@media (max-height: 650px) {
    .modal{
     top: 11%;
    }

    .backdrop {
        top: 10.3%;
    }
}

@media (max-height: 600px) {
    .modal_text {
        font-size: 0.9rem;
    }
}


@media (max-height: 551px) {
    .backdrop {
        top: 12.5%;
    }
    .modal {
        top: 14%;
        padding-bottom: 7px;
    }
    .modal_buttons_container {
        margin-top: 0;
    }
  }