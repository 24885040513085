.faq_header {
    background-color: #3565A3;
    font-family: Nunito;
    color: #D9D9D9;
}

.goBack {
    font-size: 1.5rem;
    font-weight: bolder;
    margin: 0;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.faq_text {
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
}

.faq_body {
    /* background-color: #D9D9D9; */
    font-family: Nunito;
    font-weight: 600;
    font-size: 1.2rem;
    color: #3565A3;
    margin-left: 1rem;
    line-height: 1.5rem;
    /* margin: 1rem; */
    /* margin-top: 0; */
}

.question_answer_container {
    /* background-color: #D9D9D9; */
    /* margin-bottom: 2rem; */
    /* padding-left: 0.5rem; */
    /* padding: 1px; */
}

.modal_span_color {
    color: red;
}

.modal_siSpan_color {
    color: #d76502;
}

@media (max-width: 640px) {
    .goBack {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .faq_text {
    font-size: 2rem;
    }
}

@media (max-width: 400px) {
    .goBack {
    font-size: 1rem;
    margin-bottom: 1rem;
    }
    .faq_text {
    font-size: 1.5rem;
    }
}