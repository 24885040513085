.page {
    /* background-color: rebeccapurple; */
    /* overflow-x: hidden; */
    overflow-y: scroll;
    scroll-behavior: smooth;
}

/* body {
    height: 100%;
}

body > div {
    height: 100%;
    overflow: scroll;
} */

.header {
    background-color: #3565A3;
    color: #D9D9D9;
    padding: 2rem;
    text-align: center;
    font-family: Nunito;
    font-size: 2rem;
    font-weight: bold;
}

.dateText {
    color: #3565A3;
    padding: 5px;
    font-family: Nunito;
    font-size: 1.3rem;
    font-weight: bold;
}
.texts__container {
    padding: 1rem;
}

.text_container {
    /* background-color: red; */
    display: flex;
    margin-top: 1rem;
}

.text {
    font-family: Nunito;
    color: #3565A3;
    margin-left: 0.5rem;
    font-size: 1.3rem;
}

@media (max-width: 600px) {
    .header {
        font-size: 1rem;
        padding: 1.4rem;
    }
    .text {
        font-size: 0.9rem;
    }
    .dateText {
        font-size: 1rem;
    }
}