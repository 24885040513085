
  
  nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    /* width: 300px; */
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 350px;
    background: #3565A3;
    box-shadow: 0px 6px 10px rgba(0, 27, 72, 0.6);
    z-index: 100;
    /* border-right: 1px solid rgba(22, 67, 158, 0.6); */
  }
  

  .backdrop{
    position: fixed;
    top: 9%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px); 
    z-index: 1000;
    width: 100%;
    left: 0;
  }

  .hamburger_button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    /* z-index: 101; */
  }

  @media (max-width: 570px) {
    .hamburger_button {
      top: 25px;
    }
  }
  
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  
  ul {
    padding-left: 25px;
    position: absolute;
    top: 100px;
    width: 300px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    z-index: 1000;
    color: #D9D9D9;
  }
  
  .li {
    list-style: none;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .newLi {
    list-style: none;
    margin-top: 100px;
    width: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .text-placeholder {
    border-radius: 5px;
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 5px;
  }
  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  