.login_button {
    background-color: rgb(211, 207, 207);
    height: 40px;
    width: 175px;
    font-size: 22px;
    border: 0px solid rgb(20, 20, 197);
    color: #3565A3;
    font-family: Lato;
    font-weight: 700;
    box-shadow: 0px 6px 10px rgba(0, 27, 72, 0.6);
    border-radius: 5px;
    margin-bottom: 0px;
    cursor: pointer;
}

.profile_nav {
    font-family: Nunito;
    font-size: 1.7rem;
}

.check_statistics_text {
    font-family: Nunito;
    font-size: 5.7rem;
}

