.keyboard {
    height: 100%;
    /* background-color: red; */
    /* margin: 0 8px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    width: 45%;
    position: relative;
    left:28%;
}
.row {
    /* background-color: green; */
    width: 100%;
    height: 33%;
    touch-action: manipulation;
}

.key {
    /* --background: var( background-color: #D9D9D9); */
    font-family: Nunito;
    font-weight: 700;
    font-size: 18px;
    border: 0;
    padding: 0;
    margin: 2px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #D9D9D9;
    color: #3565A3;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}
 
#correct {
    color: #fff;
    animation: coloringCorrect 3.5s ease forwards; 
    transition: all 0.2s ease-in; 
}
 

#almost_correct {
    color: #fff;
    /* --background: var(--background = #FCBC29); */
    animation: coloringAlmostCorrect 3.5s ease forwards;
    transition: all 0.2s ease-in;
}

#wrong {
    color: #fff;
    animation: coloringWrong 3.5s ease forwards;
    transition: all 0.2s ease-in;
}

#beforeAlmostNowCorrect {
    color: #fff;
    animation: coloringBefAlmostNowCorrect 3.5s ease forwards;
    transition: all 0.2s ease-in;
}

@keyframes coloringBefAlmostNowCorrect {
    
    0% {
        background: #FCBC29;
        color: #fff;

    }
 
    50% {
        background: #FCBC29;
        color: #fff;
    }
    
    100% {
    background: #36907E;
    }
}

@keyframes coloringCorrect {
    
    0% {
        background: #D9D9D9;
        /* background: var(--background); */
        color: #3565A3;

    }
 
    50% {
        background: #D9D9D9;
        /* background: var(--background); */
        color: #3565A3;
    }
    
    100% {
    background: #36907E;
    }
}

@keyframes coloringAlmostCorrect {
    
    0% {
        background: #D9D9D9;
        color: #3565A3;

    }
 
    50% {
        background: #D9D9D9;
        color: #3565A3;

    }
    
    100% {
    background: #FCBC29;
    }
}

@keyframes coloringWrong {
    
    0% {
        background: #D9D9D9;
        color: #3565A3;

    }
 
    50% {
        background: #D9D9D9;
        color: #3565A3;

    }
    
    100% {
    background: #919090;
    }
}

 .keyboard-rows{
    display: flex;
    width: 100%;

 }

.keyboard-rows-second{
width:90%;
margin-left: 5%;
}

@media only screen and (max-width: 600px) {
    .keyboard {
        width: 90% !important;
        left: 5% !important;
    }

    .key {
        height: 40px;
    }
}

@media (max-height: 700px) {
    .key {
        height: 30px;
    }
  }

  @media (max-height: 551px) {
    .key {
      height: 25px;
      font-size: 0.7em;
    }
  }