.game_timer {
    display: flex;
    font-family: Nunito;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
}

.timer_text {
    font-size: 1.3rem;
    color: #3565A3;
    /* margin-bottom: 0.5rem; */
}

.time_element {
    /* background-color: rgb(76, 2, 145); */
    background-color: #3565A3;
    font-weight: 800;
    font-size: 1.6rem;
    color: rgb(203, 197, 189);
    /* color: #d76502; */
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 3rem;
}

@media (max-width: 910px) {
    .timer_text {
        font-size: 1.6em;
    }
    .time_element {
        font-size: 2em;
    }
}

@media (max-width: 790px) {
    .timer_text {
        font-size: 1.5em;
    }
    .time_element {
        font-size: 1.8em;
    }
}

@media (max-width: 600px) {
    .timer_text {
        /* margin-left: 1rem; */
        font-size: 1.4em;
    }
    .time_element {
        font-size: 2.5em;
        margin-left: 1em;
        padding: 1px;
        padding-left: 9px;
        padding-right: 9px;
    }
}

@media (max-height: 600px) {
    .time_element {
    font-size: 1.2rem;
    }
    .timer_text {
    font-size: 1rem;
    }
}