.sub_header_elements__container {
    /* background-color: red; */
    display: flex;
    margin-bottom: 0.375rem;
    /* width: 20%; */
}

.icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.375rem;
}

.sub_header_text {
    display: flex;
    /* background-color: lightgoldenrodyellow; */
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #3565A3;
    line-height: 1.36375rem;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 1.022rem;
    /* max-width: 10.353125em; */
}

.sub_header_text_withIcon {
    display: flex;
    /* background-color: yellowgreen; */
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #3565A3;
    line-height: 1.36375rem;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    /* max-width: 20.353125em; */
}



/* #normal {
    color: #3565A3;
}
#orange {
    color: rgb(186, 122, 4);
}
#dark_red {
    color: red;
} */