.clues_box {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 100%;
    background-color: #D9D9D9;
    border-radius: 18px;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media (max-width: 570px) {
    .clues_box {
        border-radius: 0.8rem;
    }
}


