/* .welcome_modal_elements__container {
    background-color: red;
} */

.header_elements_container {
    /* width: 100%; */
    /* align-self: center; */
    /* margin-bottom: 0rem; */
    /* display: flex; */

    /* flex-direction: row; */
    /* background-color: gray; */
}

.number_header {
    /* margin-top: 0rem; */
    background-color: green;
    font-size: 1.25rem;
    font-family: Nunito;
    font-weight: 600;
    line-height: 1.705rem;
    color: #3565A3;
    /* margin-bottom: 0.375rem; */
    /* margin-top: 0.5rem; */
}

.welcome_modal_sub_elements_container {
    /* background-color: rebeccapurple; */
    width: 81.428%;

    /* display: flex; */
    /* justify-content: space-between; */
    margin-left: 11.667%;
    /* width: 50%; */
    /* margin-bottom: 0.5rem; */
}

.welcome_modal_sub_elements_container_withIcon {
    margin-left: 9.762%;
    width: 83.333%;
    /* background-color: aqua; */
}

.sub_header {
    margin-top: 0rem;
    /* background-color: green; */
    font-size: 1.25rem;
    font-family: Nunito;
    font-weight: 600;
    line-height: 1.705rem;
    color: #3565A3;
    margin-bottom: 0.375rem;
    /* margin-top: 0.5rem; */
}

.sub_header_withIcon {
    margin-top: 0rem;
    font-size: 1.25rem;
    font-family: Nunito;
    font-weight: 600;
    line-height: 1.705rem;
    color: #3565A3;
    margin-bottom: 0.375rem;
    margin-left: 0.365rem;
}





