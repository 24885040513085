.facebook_twitter_buttons__container {
    width: 33%;
    display: flex;
    justify-content: space-around;

}

.face_tweet_empty__div {
    width: 20%;
}

.siStory_modal_AppButton {
    width: 33%;
    display: flex;
    justify-content: center;
}

.empty_div {
    width: 33%;
}

.tool_tip__siStory {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
}