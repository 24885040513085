.stat_non_register__text {
    margin: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9rem;
    color: #3565A3;
    /* margin-bottom: 1rem; */
    margin-top: 1rem;
    align-self: center;
}

.stat_non_register__header {
 height: 70px;
 background-color: #3565A3;
 color: #D9D9D9;
}

.stat_non_register__icon_text_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 1rem;
}

.stat_non_register__icon {
    font-size: 120px;
}

.stat_non_register__statistics_withicons_container {
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.stat_non_register__text__number {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 7rem;
    color: #3565A3; 
    align-self: center;
    margin: 0;
}

.register_modal__AppButton {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 530px) {
    .stat_non_register__statistics_withicons_container {
        display: flex;
        flex-direction: column;
    } 
    .stat_non_register__icon {
        font-size: 50px;
    }
    .stat_non_register__text {
        font-size: 1.9rem;
    }
    .stat_non_register__text__number {
        font-size: 32px;
        word-break: break-word;
    }
}