.error_message {
    background-color: #3565A3;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D9D9D9;
    font-family: Nunito;
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 600px) {
    .error_message {
        font-size: 2.5rem;
    }
}