.siword_container {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: #3565A3;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

.game_name__container {
    height: 12%;
    display: flex;
    align-items: center;
    /* background-color: aquamarine; */
}

.clue_letters_box__container {
    height: 6.75%;
    width: 100%;
    margin-top: 1.5rem;
    /* background-color: bisque; */
    display: flex;
    justify-content: center;
}

.clue_letters_box {
    height: 100%;
    width: 22.5rem;
    /* width: 40%; */
}

.intersection_line__container {
    position: absolute;
    width: 100%;
    top: 9.5%;
    z-index: 1;
    background-color: black;
}

.game_field_container {
    /* background-color: grey; */
    width: 100%;
    height: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}



.words_board__container {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
    margin-top: 1rem;
}

.keyboard_container {
    position: fixed;
    bottom: 2%;
    width: 100%;
}

@media (max-width: 700px) {
    .clue_letters_box__container {
        width: 80%;
        align-self: center;
        height: 4.75%;
    }
}

@media (min-height: 900px) {
    .intersection_line__container {
        top: 8%;
    }
}
@media (min-height: 1000px) {
    .intersection_line__container {
        top: 7%;
    }
}
@media (min-height: 1100px) {
    .intersection_line__container {
        top: 6%;
    }
}
@media (min-height: 1100px) {
    .intersection_line__container {
        top: 5.5%;
    }
}

@media (max-height: 720px) {
    .clue_letters_box__container {
        height: 5.75%;
    }
}

@media (max-height: 670px) {
    .intersection_line__container {
        top: 10%;
    }
}

@media (max-height: 651px) {
    .intersection_line__container {
    top: 11.5%;
    }
  }

  @media (max-height: 551px) {
    .intersection_line__container {
    top: 12.5%;
    }
  }

