.container {
    flex: 33%;
    height: 100%;
    display: flex;
}

.clue_letter_container {
    display: flex;
    height: 100%;
    flex: 33%;
    justify-content: center;
    align-items: center;
    /* margin-right: -1.5px; */
    /* border: 1px solid #3565A3;    ; */
}

.clue {
    display: flex;
    justify-content: center;
    align-items: center;
}

.line_container {
    height: 100%;
    display: flex;
}

.line {
    height: 76.467%;
    align-self: center;
    width: 1px;
    background-color: #3565A3;
}

#correct_box {
    animation: fillBox 1s ease forwards ;
}

#correct_clue_container {
    animation: fillClueLetterContainer 1.5s ease forwards ;
}

#disappering_line {
    -webkit-animation: disappearingLine 1.5s ease 0.01s forwards ;

}

#no_line_container {
    -webkit-animation: disappearingLineContainer 3s ease 0.01s forwards;
}

@keyframes fillBox {
    0%{
    background-color: #D9D9D9;
    }
    
    100%{
    /* background-color: rgb(48, 136, 48); */
    /* background-color:  #36907E; */
    /* background-color: #246156; */
    background-color:  rgb(48, 136, 48);
    /* margin-right: -1.5px; */
    /* background-color: rgb(58, 151, 58); */
    }
}

@keyframes fillClueLetterContainer {
    0% {
         transform: scale(0);
    }

    20% {
        transform: scale(0); 
    }
    50% {
        transform: scale(0); 
    }
    70% {
        transform: scale(0); 
    }

    100%{
    /* margin-right: -1.5px; */
    -webkit-transform: scale(1);
    }
}

@keyframes disappearingLine {
    0% {
    height: 76.467%;
    transform: scale(1);
    }
    
    100% {
    /* height: 0%; */
    transform: scale(0);
    }
}

@keyframes disappearingLineContainer {
    0% {
    height: 100%;
    }
    /* 50% {
    height: 100%;
    } */
    30% {
    height: 100%;
    background-color:  rgb(48, 136, 48);
    /* background-color:  #36907E; */
    /* background-color: #246156; */

    }
    100% {
    height: 100%;
    margin-right: -2px;
    }
}

/* .line_container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin-left: 0px;
}

.line {
 height: 76.467%;
 width: 100%;
 width: 1.5px;
 background-color: #3565A3;
}

.letter_box_container {
    height: 100%;
    width: 100%;
    flex: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -2px;
}

.letter_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#firstBoxLine {
    height: 0% ;
}

#box {
    animation: fillBox 2s ease forwards ;
}

#clueContainer {
    animation: fillClueLetterContainer 3s ease forwards ;
    transition: all 2s ease-in;
}

#no_line_container {
    animation: disappearingLineContainer 3s ease forwards;
}

#no_line {
  animation: disappearingLine 3s ease forwards;
}

@keyframes fillBox {
    0%{
    background-color: #D9D9D9;
    }
    100%{
    background-color: rgb(48, 136, 48);
    }
}

@keyframes fillClueLetterContainer {
    0% {
         transform: scale(0);
    }

    20% {
        transform: scale(0); 
    }
    50% {
        transform: scale(0); 
    }
    70% {
        transform: scale(0); 
    }

    100%{
    transform: scale(1);
    }
}

@keyframes disappearingLine {
    0% {
    height: 76.467%;
    }
    50% {
    height: 76.467%;
    }
    
    100% {
    height: 0%;
    }
}

@keyframes disappearingLineContainer {
    0% {
    height: 100%;
    }
    50% {
    height: 100%;
    }
    100% {
    height: 100%;
    background-color:  rgb(48, 136, 48);
    }
}
 */
