.info_modal_text__title {
}

.info_modal_text__subheader_text {
    font-size: 20px;
}

.info_modal_text__button__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 450px) {
    .info_modal_text__title {
        font-size: 20px;
    }
    .info_modal_text__subheader_text {
        font-size: 18px;
    }
}