.why_register_header {
    height: 3rem;
    background-color: #3565A3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 1em;
    font-weight: 700;
    color: beige;
    margin-bottom: 1rem;
}

.register_modal__subheader__text {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-right: 1rem;
}

.why_register_line {
    margin-top: 2rem;
    height: 1px;
    width: 100%;
    background-color: #3565A3;
}

.register_modal__subheader__icon{
    margin-top: 13.2px;
    margin-left: 0.8rem;
}

.register_modal__AppButton {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.why_register_buttons_container {
    margin: 0.8rem;
    /* background-color: red; */
    display: flex;
}

.why_register_button {
    background-color: #3565A3;
    width: 30%;
    height: 2rem;
    margin-right: 1rem;
    color: beige;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 530px) {
    .register_modal__subheader__text {
        margin-right: 0.5rem;
    }
}




