.menu {
    /* height: 19rem; */
    /* width: 2.5rem; */
    display: flex;
    /* justify-content: space-between; */
}

img {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5em;
}

@media (max-width: 870px) {
    img {
        height: 2.1rem;
        width: 2.1rem;
    }
}

@media (max-width: 800px) {
    img {
        height: 1.9rem;
        width: 1.9rem;
    }
}

@media (max-width: 650px) {
    img {
        height: 1.6rem;
        width: 1.6rem;
    }
}


@media (max-width: 570px) {
    
}




