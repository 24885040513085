@media (max-height: 711px) {
    .subheaderText {
        font-size: 0.9rem;
    }
    .button_container {
        margin-top: -33px;
    }
    
}

@media (max-height: 601px) {
    .subheaderText {
        font-size: 0.9rem;
    }
    .button_container {
        margin-top: -75px;
    }
}