.siReport_subheader_text {
    font-size: 18px;
}

.siReport_AppButton__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 450px) {
    .siReport_subheader_text {
        font-size: 15px;
    }
}