.letter {
    font-family: Lato;
    font-size: 1.5rem;
    color: #3565A3;
    font-weight: 700;
}

@media (max-width: 800px) {
    .letter {
        font-size: 1.3rem;
    }
}

@media (max-width: 650px) {
    .letter {
        font-size: 1.1rem;
    }
}

@media (max-width: 570px) {
    .letter {
        font-size: 0.875rem;
    }
}

