.level_container {
    display: flex;
    /* background-color: blueviolet; */
    width: 100%;
    margin: 3px;
}

.left_elements__sontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
}

.left_view {
    height: 100%;
    width: 30%;
    background-color: rgb(100, 98, 98);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.right_elements__sontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
}

.right_view {
    height: 100%;
    width: 30%;
    background-color: rgb(100, 98, 98);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.level_text {
    font-family: Nunito;
    font-weight: bolder;
    color: #3565A3;
    font-size: 1.5rem;
    margin: 0;
}

.level_number {
    font-family: Nunito;
    font-weight: bolder;
    color: #3565A3;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}

#correct {
    background-color: rgb(48, 136, 48);
}
#correct_text {
    color: rgb(48, 136, 48);
}



@media (max-width: 580px) {
   .level_container {
        margin: 0;
    }

    .level_number {
        font-size: 1.1rem;
    }

   .level_text {
    font-size: 1.1rem;
   }

   .left_view {
    height: 70%;
   }

   .right_view {
    height: 70%;
   }

   
}

@media (max-height: 600px) {
    .level_text {
        font-size: 0.9rem;
    }
    .level_container {
        margin: 0px;
    }
}


