.title {
    font-family: Nunito;
    font-weight: 700;
    font-size: 26px;
    line-height: 31.2px;
    color:  #3565A3;
    margin-bottom: 10px;
    margin-top: 10px;
}

/* .MuiTypography-root {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.subheader_text {
    font-size: 1.5rem;
    font-family: Nunito;
    font-weight: 600;
    color: #3565A3;
    margin-top: 0;
    text-align: center;
    margin-bottom: 10px;
}

.text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #3565A3;
    margin: 0px;
    margin-bottom: 10px;
    
}

#without_icon {
    margin-right: 10px;
    margin-left: 10px;
}

.button {
    display: flex;
    align-self: center;
    margin-bottom: 1rem;
}

.text_icon__container {
    display: flex;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #3565A3;
    padding: 0;
}

.icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 5px;
}

.modal_span_color {
    color: red;
}

.modal_siSpan_color {
    color: #d76502;
}

.button_start {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 100%;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    border: 0px;
    border-radius: 0.625rem;
    cursor: pointer;
}

.button_start>img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0;
    margin-left: 20px;
}

.button_start>p {
    color: rgb(217, 217, 217);
    margin: 13px 11px;
    font-size: 20px;
}

@media (max-width: 450px) {
    .title{
        margin-bottom: 0;
        margin-top: 10px;
    }

    .subheader_text {
        margin: 0 0 10px 0;
        font-size: 18px;
        text-align: center !important;
    }
    .MuiDialogContent-root {
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
    }
    .icon {
        margin-left: 0;
    }
    .text {
        margin-bottom: 5px;
    }
    .button_start>img {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 0px;
        margin-left: 10px;
    }

    .button_start>p {
        color: rgb(217, 217, 217);
        margin: 10px 10px;
        font-size: 18px;
    }

    .css-lc2yqr-MuiButtonBase-root-MuiIconButton-root{
        height: 30px;
        width: 30px;
    }
}