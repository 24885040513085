/* .modal_end{
    position: fixed;
    top: 20%;
    width: 26.25rem;
    background: #BEBDBD;
    box-shadow: 0px 10px 20px rgba(0, 27, 72, 0.6);
    border-radius: 26px;    
    z-index: 100;
    padding: 15px 0;
    left: 5%;   
} */

.modal {
    /* position: fixed; */
    top: 20%;
    width: 26.25rem;
    background: #BEBDBD;
    box-shadow: 0px 10px 20px rgba(0, 27, 72, 0.6);
    border-radius: 26px;    
    z-index: 100;
    padding: 15px 0;
    z-index: 10000;
    /* left: 5%; */
}
.slideshow-app{
    z-index: 10000;
    width: 420px;
}



.backdrop {
    position: fixed;
    /* top: 9%; */
    bottom: 0%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    z-index: 10;
    width: 100%;
    left: 0;
}

.modal_container {
    display: flex;
    justify-content: center;
}

.siQuiz_modal__container {
    padding: 15px;
}

.siQuizModal_header_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.siQuizModal_header {
    font-family: Nunito;
    font-weight: 600;
    color: #3565A3;
    font-size: 1.313rem;
    line-height: 1.79rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.clue_letters_box_inmodal__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.clue_letters_box_inmodal {
    width: 22.5rem;
    height: 3.375rem;
}

.clue_lettter_box_inmodal {
    border: 1px solid rgba(53, 101, 163, 0.7);
    box-shadow: 0px 5px 10px rgb(0 27 72 / 30%);
    border-radius: 1.625rem,
}


.subheader {
    margin-bottom: 1rem;
}

.siQuizModal_footer {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: normal;
    line-height: 2.563rem;
    margin-top: 0.5em;
}

.start_game_button__container {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 0.5em;
    background-color: red;
    width: 47.1%;
    align-self: center;
}

@media only screen and (min-width: 601px) {
    .modal_container {
        display: contents;
    }
}


@media only screen and (max-width: 600px) {
    .modal {
        width: 22em;
        /* left: 5%;  */
    }
}