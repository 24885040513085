.header {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.625rem;
    color: #3565A3;
}

@media (max-width: 580px) {
    .header{
        font-size: 1.4rem;
    }
}

@media (max-height: 600px) {
    .header {
        font-size: 1.2rem;
    }
}