.variant_text{
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    color: #3565A3;
    line-height: 1.4rem;
}

.active_variant_text {
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    color: #D9D9D9;
}

#text_font_init {
    /* color: red; */
    font-size: 0.8rem;
    margin: 0;
    line-height: 0.9rem;
}

#text_font_medium {
    font-size: 0.5rem;
    margin: 0;
    line-height: 0.8rem;
}

#text_font_last {
    font-size: 0.3rem;
    margin: 0;
    line-height: 0.7rem;
}

@media (max-width: 800px) {
    .variant_text {
        font-size: 1.1rem;
    }
    .active_variant_text {
        font-size: 1.1rem;
    }
}

@media (max-width: 650px) {
    .variant_text {
        font-size: 0.9rem;
        line-height: 1;
    }
    .active_variant_text {
        font-size: 0.9rem;
        line-height: 1;
    }
    .variant_text {
        line-height: 1rem;
    }
}

@media (max-height: 620px) {
    .variant_text {
        font-size: 0.8rem;
    }
}