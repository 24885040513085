.question_number {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 0.875rem;
    overflow: hidden;
}

@media (max-width: 570px) {
    .question_number {
        border-radius: 0.375rem;
    }
}