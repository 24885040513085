.number_container {
    height: 73.04%;
    border-right: 1px solid rgb(170, 169, 169);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    flex: 33%;
    /* margin-right: -4px; */
    /* background-color: #C24328; */
}

#number_container_active {
    border-right: 1px solid #3565A3;
}

#number_active {
 color: #000000;
 width: 100%;
 height: 150%;
 font-size: 28px;
 /* border-radius: 10px; */
 /* border-radius: 0.875rem; */
 /* border-radius: 50%; */
 display: flex;
 justify-content: center;
 align-items: center;
 /* background-color: rgb(153, 152, 152); */
}

#number_choosen {
    color: #FCBC29;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150%;
    font-size: 28px;

    /* background-color: rgb(153, 152, 152); active background  */
}

.last_number {
    /* border-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    flex: 33%; */
    height: 73.04%;
    border-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    flex: 33%;
}

.number {
 font-family: Lato;
 font-weight: 700;
 font-size: 1.375rem;
 color: rgb(170, 169, 169);
}

@media (max-width: 800px) {
    .number {
       font-size: 1.175rem;
    }
}

@media (max-width: 650px) {
    .number {
       font-size: 0.975rem;
    }
}

@media (max-width: 570px) {
    .number {
       font-size: 0.875rem;
    }
    #number_active {
        font-size: 23px;
    }
    #number_choosen {
        font-size: 23px;
    }
}

#number_correct {
    color: #2b7467;
}

#number_wrong {
    color: #c40424;
}

