.quiz_page_container {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: #3565A3;
    display: flex;
    justify-content: center;
}

.game_name__container {
    position: absolute;
    top: 18px;
    width: 100%;
    align-self: center;
}

.clue_letters_box__container {
    position: absolute;
    top: 4%;
    width: 39.75rem;
    height: 3.75rem;
}

.intersection_line__container {
    position: absolute;
    background-color: black;
    top: 15%;
    /* top: 25.5%; */
    width: 100%;
}

.first_question_text__container {
    position: absolute;
    top: 20%;
    background-color: #D9D9D9;
    border-radius: 10px;
}

.first_question_text {
    font-family: Nunito;
    /* color: #36907E; */
    color: #3565A3;
    font-size: 1.5rem;
    /* font-weight: 400; */
    margin: 0.7rem;
    /* line-height: 1.705rem; */
}

.question_container {
    /* height: auto; */
    /* width: 60%; */
    /* display: flex;   
    display: -webkit-flex;  */
    align-items: center; 
    position: absolute;
    justify-content: center;
    top: 25.5%;
    text-align: center;
}

.question_container>p{
    display: -webkit-box;
    width: auto;
}
.variants_container {
    top: 50.25%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0%;
    /* background-color: red; */
}

.variants_blocks {
    margin: 0%;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1.25rem;
    padding-left: 0%;
    top: 0px;
    width: auto;
    right: inherit;
    z-index: 0;
}

.SiQuiz_button_container {
    position: absolute;
    /* top: 59.25%; */
    top: 62.25%;
    width: 15.375rem;
    height: 3.125rem;
    z-index: 0;
}

.numbers_box__container {
    position: absolute;
    /* top: 81.5%; */
    top: 79.5%;
    width: 39.75rem;
    height: 3.125rem;
}

.open_question__container {
    /* height: 1.6875rem; */
    width: 37.0625rem;
    display: flex;   
    text-align: center;
    align-items: center; 
    position: absolute;
    justify-content: center;
    /* top: 41.5%; */
    top: 35%
}

.menu_box__container {
    position: absolute;
    top: 41.5%;
    right: 5.1%;
}

@media (max-width: 800px) {
    .clue_letters_box__container{
        width: 30.75rem;
        height: 3rem;
    }
    .question_container {
        height: 1.3875rem;
        width: 29.0625rem;
        text-align: center;
    }
    .open_question__container {
        /* height: 1.3875rem; */
        width: 29.0625rem;
    }
    .numbers_box__container {
        width: 29.75rem;
        height: 3rem;
    }
    .SiQuiz_button_container {
        width: 10.375rem;
        height: 3rem;
    }
}

@media (max-width: 650px) {
    .clue_letters_box__container{
        top: 4%;
        width: 25.75rem;
        height: 2.5rem;
    }
    .question_container {
        height: 1.1875rem;
        width: 23.0625rem;
        text-align: center;
    }
    .open_question__container {
        /* height: 1.1875rem; */
        width: 23.0625rem;
    }
    .numbers_box__container {
        width: 24.75rem;
        height: 2.5rem;
    }
    .SiQuiz_button_container {
        width: 9.375rem;
        height: 2.5rem;
    }
}



@media (max-width: 570px) {
    .first_question_text__container {
        position: absolute;
        top: 18%;
        text-align: center;
    }

    .clue_letters_box__container{
        /* top: 14.901%; */
        top: 8%;
        width: 80%;
        height: 5.187%;
    }
    .question_container {
        top: 31.897%;
        width: 85.333%;
        text-align: center;
    }
    .open_question__container {
        /* top: 43.897%; */
        width: 85.333%;
        text-align: center;
    }
    .numbers_box__container {
        top: 87.808%;
        width: 80%;
        height: 4.695%;
    }
    .SiQuiz_button_container {
        top: 62.759%;
        /* width: 45.333%; */
        width: auto;
        height: 5.419%;
    }
    .intersection_line__container {
        /* top: 10.961% */
        top: 1%;
    }
    /* .game_name__container {
        top: 2.956%;
    } */
    .variants_container {
        top: 55.049%;
        width: 84%;
    }
    .variants_blocks{
        height: 100%;
        width: 100%;
        row-gap: 0.5rem;
        column-gap: 0.9375rem;
        padding-left: 0%;
        left: 0;
    }
}


@media (max-width: 900px) {
    .menu_box__container {
        right: 3.1%;
    }
}

@media (max-width: 840px) {
    .menu_box__container {
        right: 1.7%;
    }
}

@media (max-width: 768px) {
    .menu_box__container {
        display: none;
    }
}

@media (max-width: 500px) {
    .menu_box__container {
        display: none;
    }
    .first_question_text__container {
        margin: 1rem;
    }

    .first_question_text {
        font-size: 1.1rem;
        font-weight: bolder;
        margin: 0.5rem;
        /* line-height: 1.705rem; */
    }

    .numbers_box__container {
        top: 80%;
    }
}

@media (max-height: 620px) {
    .variants_container {
        top: 50.25%;
    }
    
    .variants_blocks {
        margin: 0%;
    }

    .question_container {
        top: 25%;
    }
}
