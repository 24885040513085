.button {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 100%;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    border: 0px;
    border-radius: 0.625rem;
}

.button:hover{
    cursor: pointer;
}

.button_text {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #36907E;
    align-self: center;
    margin-right: 10px;
}

.icon {
    width: 1.719rem;
    height: 1.563rem;
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
    /* margin-right: 0.719rem; */
}




