.variant_letter {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 1rem;
    color: #3565A3;
    margin-right: 0.75rem;
}

.active_letter {
    font-family: Lato;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 1rem;
    color: #D9D9D9;
    margin-right: 2.125rem;
}

@media (max-width: 800px) {
    .variant_letter {
        font-size: 1.3rem;
    }
    .active_letter {
        font-size: 1.3rem;
    }
}

@media (max-width: 650px) {
    .variant_letter {
        font-size: 1.1rem;
        margin-right: .25rem;
        margin-left: 0.5rem;
    }
    .active_letter {
        font-size: 1.1rem;
        margin-right: .25rem;
    }
}