body{
  user-select:none;
  /* background-color: #3565A3; */
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.GameModal_go_to_settings_text__rJukt{
  padding: 0;
}

.button_copy_text{
  background: none;
  border: none;
}

.button_copy_text > svg{
  height: 2em;
  width: 2em;
  cursor: pointer;
}
#without_icon{
  width: 100% !important;
  text-align: center;
}

.community_div{
  display: inline-flex;
}

.deleteButton{
  margin-left: 0em !important;
}

.share-button{
  background-color: rgb(162, 171, 40) !important;
  padding: 10px 20px;
  color:white;
}


#desktop{
  display: block !important;
}
#mobile{
  display: none !important;
}

@media (max-width: 768px) {
  #desktop{
    display: none !important;
  }
  #mobile{
    display: block !important;
  }
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}


.faq_answer{
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  border-left: 5px solid #b5b5b5;
  padding-left: 5px;
}

.faq_question{
  display: flex;
  margin-bottom: 0;
  border-left: 5px solid red;;
  padding-left: 5px;
}

.faq_quest{
  padding-top: 15px;
  padding-left: 10px;
}

.faq_quest_b{
  padding-top: 10px;
  padding-left: 10px;
}

.faq_letter{
  font-size: 32px;
}


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
.dropdown-header{
  margin: 0;
}

.navbar-expand-md{
  position: relative;
}

.MuiTabs-flexContainer{
  display: block !important;
  text-align: center;
}

.button-story{
  background: #3565a3;
  border-radius: 25px;
  animation-name: button_story;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes button_story {
  0%   {transform: rotate(0);}
  25%  {transform: rotate(90deg);}
  35%  {transform: rotate(125deg);}
  50%  {transform: rotate(180deg);}
  65%  {transform: rotate(225deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(1000deg);}
}

.image-story{
  height:140px;
  width: 100%;
}

.card-story{
  padding:10px;
}

.button_block{
  display: flex;
}

.table_register{
  display: block;
}

.table_non_register{
  display: none !important;
}

.report-button-text{
  margin:0.5em;
}

.report-div-block{
  display: flex;
}

.main_user{
  background-color: aquamarine;
}

.winner_user{
  display: none !important;
}


.background{
  z-index: 1000 !important;
}
.hamburger_button{
  z-index: 1000 !important;
}

/* .button-div-non-register>button{
  width: 100%;
} */


.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
}



.each-slide-effect-1 > div, .each-slide-effect-2 > div, .each-slide-effect-3 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: 35%;
  height: 400px;
}


.slideshow-app{
  width: 100%;
  position: relative;
  /* left: 25%; */
}
.each-slide-effect{
  background: #BEBDBD;
  
}

.slideshow-app-modal{
  z-index: 10000 !important;
    width: 420px !important;
    border-radius: 26px !important;
}

.each-slide-effect-3-modal > div {
  left: 28% !important;
}

.each-slide-effect-3-modal{
  border-radius: 26px;
  height: 100%;
}

.react-slideshow-wrapper.slide{
  /* border-radius: 26px; */
}

@media (max-width: 450px) {
  .slideshow-app-clues > div > div>.default-nav:first-of-type{
    left: 35px;
  }

  .slideshow-app-clues > div > div>.default-nav:last-of-type{
    right: 35px;
  }


  .react-slideshow-container .nav:last-of-type{
    /* right: 20px !important; */
  }
  .react-slideshow-container .nav:first-of-type{
    /* left: 20px !important; */
  }
  .each-slide-effect-1 > div, .each-slide-effect-2 > div, .each-slide-effect-3 > div {
    left: 18%;
  }
  .each-slide-effect-3-modal > div{
    left: 28% !important;
  }
}
html {
    overflow: hidden;
    height: 100%;
}