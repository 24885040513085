/* body {
  text-align: center;
  margin: 0;
} */

.si_row {
    text-align: center;
    display: flex;
    justify-content: center;
    /* background-color: #36907E; */
    /* height: 100%; */
    /* width: 100%; */
}

.si_row > div {
    display: block;
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 1.6px solid #D9D9D9;
    box-shadow: 0px 6px 10px rgba(0, 27, 72, 0.6);
    margin: 3px;
    text-align: center;
    line-height: 27px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
}

#game_is_finished {
  animation: isCorrectAnimation 1s ease forwards ;
}


.si_row > div.correct {
  --background: #36907E;
  animation: flip 0.6s ease forwards;
}
.si_row > div.wrong {
  --background: #909090;
  animation: flip 0.6s ease forwards;
}
.si_row > div.almost_correct {
  --background: #FCBC29;
  animation: flip 0.5s ease forwards;
}

.si_row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.si_row > div:nth-child(3) {
  animation-delay: 0.4s;
}
.si_row > div:nth-child(4) {
  animation-delay: 0.6s;
}
.si_row > div:nth-child(5) {
  animation-delay: 0.8s;
}
.si_row > div:nth-child(6) {
  animation-delay: 1s;
}
.si_row > div:nth-child(7) {
  animation-delay: 1.2s;
}




@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #3565A3;
  }
  45% {
    transform: rotateX(90deg);
    background: #3565A3;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
  }
  100% {
    transform: rotateX(0deg);
    background: var(--background);
    color: #eee;
  }
}

@keyframes isCorrectAnimation {
  0% {
    transform: scale(1.0);
}

10% {
  transform: scale(1.01);
}

20% {
  transform: scale(1);
}

30%,

40% {
  transform: scale(1);
}

50% {
  transform: scale(1.01);
}

60% {
  transform: scale(1);
}

70%,

80% {
  transform: scale(1);
}

90% {
  transform: scale(1.01);
}

100% {
    transform: scale(1);
}
}


@media (max-width: 500px) {
  .si_row > div {
    width: 38px;
    height: 38px;
    border-radius: 50px;
  }
}

@media (max-height: 700px) {
  .si_row > div {
    width: 30px;
    height: 30px;
    /* line-height: 140px; */
  }
}

@media (max-height: 551px) {
  .si_row > div {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }
}