.modal {
    position: fixed;
    top: 15%;
    width: 50%;
    background: #BEBDBD;
    box-shadow: 0px 10px 20px rgba(0, 27, 72, 0.6);
    border-radius: 26px;    
    z-index: 100;
    padding: 15px 0;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* left: 5%; */
}

.backdrop {
    position: fixed;
    top: 9%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    z-index: 10;
    width: 100%;
    left: 0;
}

.modal_container {
    display: flex;
    justify-content: center;
}

.submodals_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.header_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.footer_container{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px 0;
    margin-bottom: 1rem;
}

.lets_siword_button__container {
    /* margin-top: 1.5rem; */
    height: 3.125rem;
    /* width: 15.375rem; */
    border-radius: 0.625rem;
    box-shadow: 0px 5px 10px rgba(112, 154, 228, 0.6);
}

.button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.go_to_settings_text__container {
    margin-top: 1rem;
    width: 70%;
    position: relative;
    top: 70px;
}

.go_to_settings_text {
    font-family: Nunito;
    font-size: 1.2rem;
    color: #3565A3;
    background-color: #d9d8d8;
    border-radius: 10px;
    border: 1px solid;
    font-weight: bold;
}


.why_register_button__container {
    width: 50%;
    margin-top: 5px;
}

.game_timer__container {
    /* background-color: red; */
    width: 90%;
    text-align: center;
    margin-top: 1rem;
}


@media only screen and (max-width: 600px) {
    .go_to_settings_text__container {
        width: 85% !important;
    }
    .modal {
        width: 22em;
    }
}