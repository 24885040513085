.statistics_modal_subheader_text {
    font-size: 20px;
}

.statistics_modal__AppButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
}

.statistics_modal__empty_div {
    width: 33%;
}

.statistics_modal__face_tweet__container {
    margin-left: 1.3rem;
    display: flex;
    justify-content: space-around;
    width: 33%;
    margin-top: 0.5rem;
}

.statistics_modal__face_tw__empty__div {
    width: 20%;
}


@media (max-width: 450px) {
    .statistics_modal_subheader_text {
        font-size: 18px;
    }
    .statistics_modal__face_tweet__container {
        margin-left: 1.8rem;

}

}