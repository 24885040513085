.clue_initial_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clue_initial {
    /* color: #C24328; */
    color: #36907E;
    font-family: Lato;
    font-weight: 600;
    font-size: 2rem;
}