.question {
    font-family: Nunito;
    color: #D9D9D9;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.705rem;
}

@media (max-width: 800px) {
    .question {
        font-size: 1.3rem;
    }
}

@media (max-width: 650px) {
    .question {
        font-size: 1.2rem;
    }
}

@media (max-width: 570px) {
    .question {
        font-size: 1.2rem;
        line-height: 1.36375rem;
    }
}

@media (max-height: 620px) {
    .question {
    font-size: 1rem;
    }
}

